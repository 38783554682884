.App {
  text-align: center;
  background: rgb(255,138,48);
  background: linear-gradient(0deg, rgba(255,138,48,1) 0%, rgba(54,167,255,1) 50%, rgba(40,113,230,1) 100%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  background: rgb(255,138,48);
  background: linear-gradient(0deg, rgba(255,138,48,1) 0%, rgba(54,167,255,1) 50%, rgba(40,113,230,1) 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-button {
  background-color: aqua;
  border: none;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.App-button:hover {
  background-color: yellow;
  color: black;
}


/*.App-button-hover {*/
/*  background-color: yellow;*/
/*  color: black;*/
/*}*/

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.switch-toggle {
  width: 10em;
}

.switch-toggle label:not(.disabled) {
  cursor: pointer;
}