.PrelimDataAcq{
    padding-top: 36px;
    background: rgb(255,138,48);
    background: linear-gradient(0deg, rgba(255,138,48,1) 0%, rgba(54,167,255,1) 50%, rgba(40,113,230,1) 100%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    max-width: initial;
}
.PrelimDataAcq .placeHolder {
    width: 600px;
}

.PrelimDataAcq .icon {
    padding-left: 10px !important;
}

.PrelimDataAcq p {
    color: black;
}

.PrelimDataAcq label {
    color: black;
}

.PrelimDataAcq h1 {
    color: black;
}

.PrelimDataAcq .icon-button {
    background-color: transparent;
    border-color: transparent;
}

.PrelimDataAcq .relative {
    position: relative;
}