.DataCollection {
    padding-top: 36px;
    background: rgb(255,138,48);
    background: linear-gradient(0deg, rgba(255,138,48,1) 0%, rgba(54,167,255,1) 50%, rgba(40,113,230,1) 100%);
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.DataCollection .placeHolder {
}

.DataCollection .icon {
    padding-left: 10px !important;
}

.DataCollection p {
    color: black;
}

.DataCollection .icon-button {
    background-color: transparent;
    border-color: transparent;
}

.DataCollection .relative {
    position: relative;
}

.DataCollection .recording {
    border-color: red;
    border-width: 3px;
    border-style: solid;
    animation: blink 2s alternate infinite;
    height: 51vh;
}
.DataCollection .not-recording {
    height: 50vh;
}

.DataCollection .map {
    height: 50vh;
    /*padding-top: 36px;*/
}
.DataCollection inline-buttons {
    display: inline-block;
    display: flex;
}
@keyframes blink{
    0%{opacity: .3;}
    50%{opacity: .75;}
    100%{opacity: 1;}
}

.DataCollection .loaderWrapper {
    width: 100%;
    align-items: center;
    color: black;
    display: flex;
    position: relative;
}