.About {
    padding-top: 36px;
    padding-bottom: 20px;
    color: black;
    background: rgb(255,138,48);
    background: linear-gradient(0deg, rgba(255,138,48,1) 0%, rgba(54,167,255,1) 50%, rgba(40,113,230,1) 100%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.About .placeHolder {
    min-width: 600px;
}

.About .buttonHolder {
    margin-inline: auto;
}

.About .icon {
    padding-left: 10px !important;
}

.About p {
    color: black;

    max-width: 600px;
}

.About .relative {
    position: relative;
}