.POI {
    padding-top: 36px;
    padding-bottom: 36px;
    background: rgb(255,138,48);
    background: linear-gradient(0deg, rgba(255,138,48,1) 0%, rgba(54,167,255,1) 50%, rgba(40,113,230,1) 100%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .POI .placeHolder {
    min-width: 315px;
    height: 33vh;
    contain: content;
  }

  .POI .icon {
    padding-left: 10px !important;
  }
  
  .POI p {
    color: black;
  }
  
  .POI .relative {
    position: relative;
  }

  .POI .loaderWrapper {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    position: relative;
    top: -100%;
  }

  .POI .preview {
    height: inherit !important;
    width: 100% !important;
    object-fit: contain;
    position: relative;
    top: 18px;
  }

  .POI .previewButton {
    position: relative;
    bottom: 35px;
  }

  .POI .placeHolder video {
    width: 100% !important;
    object-fit: contain;
    max-width: 378px;
    padding-top: 5px;
  }

  .POI .swap {
    position: absolute;
    left: 0;
  }

  .swap :first-child {
    position: absolute !important;
    z-index: 1;
    left: 0;
    top: 10px;
    overflow: visible !important;
    width: 15%;
    height: 18%;
  }

  .swap :first-child.ui {
    z-index: 3;
  }

  .swap :first-child i {
    font-size: 150% !important;
    color: white;
  }

  #container-circles {
    bottom: 100px !important;
  }